<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar
            :to="
              `/cliente/${$route.params.cliente}/contrato/${$route.params.contrato}/veiculos`
            "
          />
        </v-col>
      </v-row>
    </v-card-actions>
    <veiculo-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      :veiculo="veiculo"
      @salvar="salvar"
    />
  </v-container>
</template>
<script>
import veiculos from "@/api/veiculos";

export default {
  components: {
    VeiculoCampos: () => import("./VeiculoCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
      veiculo: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await veiculos.buscar(this.$route.params.veiculo);
        var veiculo = {
          placa: resposta.data.data.placa,
          descricao: resposta.data.data.descricao,
          tipo_veiculo_id: resposta.data.data.tipo_veiculo_id,
        };

        this.veiculo = veiculo;
      } catch (e) {
        this.$router.push(
          {
            name: "Veiculos do contrato",
            params: {
              cliente: this.$route.params.cliente,
              contrato: this.$route.params.contrato,
            },
          },
          () =>
            this.$snackbar.mostrar({
              cor: "error",
              mensagem: this.$erroApi._(e),
            })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(veiculo) {
      this.carregando = true;
      this.validacaoFormulario = {};
      veiculo.id = this.$route.params.veiculo;
      veiculo.contrato_id = this.$route.params.contrato;

      console.log(veiculo);

      try {
        await veiculos.atualizar(veiculo.id, veiculo);

        this.$router.push(
          {
            name: "Veiculos do contrato",
            params: {
              cliente: this.$route.params.cliente,
              contrato: this.$route.params.contrato,
            },
          },
          () =>
            this.$snackbar.mostrar({
              cor: "success",
              mensagem: this.$mensagens._("sucesso_editar"),
            })
        );
      } catch (e) {
        if (e.response.status === 422) {
          console.log(e.response);
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
