import axios from "@/plugins/axios";

export default {
  async listar() {
    return await axios.get(`veiculos`);
  },

  async buscar(id) {
    return await axios.get(`veiculos/${id}`);
  },

  async inserir(dados) {
    return await axios.post(`veiculos`, dados);
  },

  async atualizar(id, dados) {
    return await axios.put(`veiculos/${id}`, dados);
  },

  async buscarVeiculosDoContrato(contrato) {
    return await axios.get(`contratos/${contrato}/veiculos`);
  },
};
